import $ from "jquery";

export class PageTimer {
    getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};
        var ms = now - timing.navigationStart;
        return Math.round(ms / 10) / 100;
    }

    logToConsole = function () {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            console && console.info && console.info(`Client loaded in ${loadTime}s`);
        });
    }

    append = function (holder) {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            holder.text(' | LT: ' + loadTime() + 's');
        });
    }
}