import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faLink as falLink, faDownload as falDownload, faFlagCheckered as falFlagCheckered } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faEdit as farEdit, faDownload as farDownload, faLink as farLink } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner as fasSpinner, faAngleDown as farAngleDown, faCheckCircle as fasCheckCircle, faPhoneSquare as fasPhoneSquare, faEnvelope as fasEnvelope, faMobile as fasMobile } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falLink, falDownload, falFlagCheckered);
// regular
library.add(farSearch, farEdit, farDownload, farLink);
// solid
library.add(fasSpinner, farAngleDown, fasCheckCircle, fasPhoneSquare, fasEnvelope, fasMobile);
// brands
library.add(faFacebook, faTwitter);

dom.i2svg();
dom.watch();