import $ from "jquery";
import "magnific-popup";
import "slick-carousel";
import WOW from 'wowjs';

export default class UIBindings {
    bindSmoothScroll(){
        // your current click function
        $('.anchor').click(function() {
          var anchor = $(this).attr('href').split('#').pop();

          var anchorTarget =  $('[data-anchor="'+ anchor +'"]' );
          console.log(anchorTarget.length, 'click', anchor);
          if (anchorTarget.length) {
             $('html, body').animate({
                scrollTop: anchorTarget.offset().top - 110
            }, 1000);
            return false;
          };
           
        });

       $(window).on('load', function(){

        // *only* if we have anchor on the url
        if(window.location.hash) {

           var anchor = window.location.hash.split('#').pop();
           
          animate(anchor);
        };
       })

        function animate(anchor){
          var anchorTarget =  $('[data-anchor="'+ anchor +'"]' );
           $('html, body').animate({
                scrollTop: anchorTarget.offset().top - 110
            }, 500);
        }
    };

    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });

    }

    googleRecaptcha() {
        function GetReCaptchaID(containerID) {
            var retval = -1;
            $(".g-recaptcha").each(function (index) {
                if (this.id == containerID) {
                    retval = index;
                    return;
                }
            });
            return retval;
        }

        $(document).on('submit', 'form', function (event) {
            var reCaptchaValue = $(this).find('[name="g-recaptcha-response"]').val();
            if (!reCaptchaValue) {
                event.preventDefault();
                var reCaptchaID = $(this).find('.g-recaptcha').attr('id');
                reCaptchaID = GetReCaptchaID(reCaptchaID);
                grecaptcha.reset(reCaptchaID);
                grecaptcha.execute(reCaptchaID);
            }
        })
    }

    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }

    slickCarourel() {
        let carousel = $('[data-slick]');
        if (carousel.length > 0) {
            carousel.slick();
        }

        

        $(".wow").each(function() {
          var wowHeight = $(this).height()  ;
          //console.log(wowHeight);
          $(this).attr("data-wow-offset", wowHeight);
          //console.log($(this).attr("data-wow-offset", wowHeight));
          new WOW.WOW().init();
      });
    }
    onScroll(){
        $(window).on('scroll', function(){
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 80) {
                $('.navbar').removeClass('bg-dark-trans');
            }else{
                $('.navbar').addClass('bg-dark-trans');
            }
        })
    }
    mobileAnimation(){
        if( $(window).width() < 768) {
            $('.wow').addClass('wow-removed').removeClass('wow');
        } else {
            $('.wow-removed').addClass('wow').removeClass('wow-removed');
        }
    }

    bootstrap (){
    	$('[data-toggle="tooltip"]').tooltip();
    }
}