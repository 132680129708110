import "bootstrap";
import "parsleyjs";

import "./fontawesome";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
// import { ShareHandler } from "./share";


(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
// (new ShareHandler()).build();

const forms = new Forms();
forms.validate();
forms.steps();

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.bootstrap();
UIBinding.bindSmoothScroll();
UIBinding.mobileAnimation();


